<template>
  <div class="message-modal-whole" ref="modalRef">
    <a-modal
      width="890px"
      title="消息通知详情"
      v-model="MessageModalVisible"
      :footer="null"
      :getContainer="() => $refs.modalRef"
    >
      <div class="message-modal-container">
        <div class="message-modal-top">
          <p class="message-modal-title">{{ messagePart.title }}</p>
          <p class="message-modal-time">{{ messagePart.publishTime }}</p>
        </div>
        <div style="margin: 20px 25px" class="message-center-content" v-html="messagePart.content"></div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'MessageModal',
  data () {
    return {}
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    messagePart: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    MessageModalVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.message-modal-whole {
  background: #ffffff;
  .message-modal-container {
    height: 838px;
    overflow-y: auto;
    box-sizing: border-box;
    .message-modal-top {
      padding: 16px;
      width: 820px;
      margin: 0 16px;
      border-bottom: 1px solid #d1d1d1;
      .message-modal-title {
        font-size: 18px;
        font-weight: 400;
        color: #000000;
        line-height: 25px;
        margin-bottom: 6px;
      }
      .message-modal-time {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
      }
      /deep/.ant-modal-wrap .ant-modal .ant-modal-body {
        padding-bottom: 30px;
        padding-top: 30px;
      }
    }
    .message-center-content {
      padding-left: 7px;
      font-size: 14px;
      font-weight: 400;
      color: #4d4d4d;
      line-height: 20px;
    }
  }
}
</style>

<style lang="less">
@import '~@/assets/less/messageCenter.less';
</style>
